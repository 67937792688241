import React, { useState, useEffect } from 'react'
import translate from './i18n'
import PropTypes from 'prop-types'
import './scss/index.scss'

const Finance = require('financejs')
const finance = new Finance()


const calculateMonthlyAmortizationCost = (
  principal,
  rate,
  paymentCountMonths,
  paymentType
) => {
  const result = finance.AM(principal, rate, paymentCountMonths, paymentType)
  return result
}

const calculateTotalCost = (monthlyPaymentAmount, paymentCountMonths) => {
  return monthlyPaymentAmount * paymentCountMonths
}

const calculateEffectiveInterestRate = (compoundings, interestRate) => {
  return Math.pow(1 + interestRate / compoundings, compoundings) - 1
}

function LoanCalculator({ language = 'sv'}) {
  const [loanAmount, setLoanAmount] = useState(100000)
  const [interestRate, setInterestRate] = useState(7)
  const [loanPeriod, setLoanPeriod] = useState(12)
  const [administrationFee, setAdministrationFee] = useState(0)
  const [arrangementFee, setArrangementFee] = useState(0)
  const [result, setResult] = useState({
    amortizationCost: 0,
    effectiveInterestRate: 0,
    totalCost: 0
  })

  const onLoanAmountChange = event => {
    const value = event.target.value
    setLoanAmount(value)
  }

  const onInterestRateChange = event => {
    const value = event.target.value
    setInterestRate(value)
  }

  const onLoanPeriodChange = event => {
    const value = event.target.value
    setLoanPeriod(value)
  }

  const onArrangeMentFeeChange = event => {
    const value = event.target.value
    setArrangementFee(value)
  }

  const onAdministrationFeeChange = event => {
    const value = event.target.value
    setAdministrationFee(value)
  }

  const calculate = () => {
    const amortizationCost = calculateMonthlyAmortizationCost(
      loanAmount,
      interestRate,
      loanPeriod,
      1 // Payment type 1 equals months
    )
    const _totalCost = calculateTotalCost(amortizationCost, loanPeriod)
    const _effectiveInterestRate = calculateEffectiveInterestRate(
      loanPeriod,
      interestRate / 100
    )

    setResult({
      amortizationCost: Math.ceil(
        parseFloat(amortizationCost) + parseFloat(administrationFee)
      ).toFixed(),
      effectiveInterestRate: _effectiveInterestRate,
      totalCost: _totalCost.toFixed()
    })
  }

  useEffect(() => {
    calculate()
  }, [loanAmount, interestRate, loanPeriod, administrationFee, arrangementFee])

  return (
    <div className="loan-calculator-container">
      <div className="input-container">
        <label className="label-container">
          <span className="input-label">
            {translate('loan_amount', language)}
          </span>
          <input
            className="loan-calculator-input"
            name="loanAmount"
            value={loanAmount}
            onChange={onLoanAmountChange}
            type="number"
          />
        </label>
        <label className="label-container">
          <span className="input-label">
            {translate('loan_period', language)}
          </span>
          <input
            className="loan-calculator-input"
            value={loanPeriod}
            onChange={onLoanPeriodChange}
            name="loanPeriod"
            type="number"
          />
        </label>
        <label className="label-container">
          <span className="input-label">
            {translate('interest_rate', language)}
          </span>
          <input
            className="loan-calculator-input"
            value={interestRate}
            onChange={onInterestRateChange}
            name="interestRate"
            type="number"
          />
        </label>
        <label className="label-container">
          <span className="input-label">
            {translate('admin_fee', language)}
          </span>
          <input
            className="loan-calculator-input"
            value={administrationFee}
            onChange={onAdministrationFeeChange}
            name="adminstrationFee"
            type="number"
          />
        </label>
        <label className="label-container">
          <span className="input-label">
            {translate('arrangement_fee', language)}
          </span>
          <input
            className="loan-calculator-input"
            value={arrangementFee}
            onChange={onArrangeMentFeeChange}
            name="arrangementFee"
            type="number"
          />
        </label>
      </div>
      {
        // RESULT SECTION
      }
      <div className="result-container">
        <label className="label-container">
          <span className="input-label">
            {translate('amortization_cost', language)}
          </span>
          <input
            className="loan-calculator-result"
            name="amortizationCost"
            value={`${result.amortizationCost} ${translate(
              'currency_symbol',
              language
            )}`}
            disabled
          />
        </label>
        <label className="label-container">
          <span className="input-label">
            {translate('effective_interest_rate', language)}
          </span>
          <input
            className="loan-calculator-result"
            name="effectiveInterestRate"
            value={(result.effectiveInterestRate * 100).toFixed(2) + ' %'}
            disabled
          />
        </label>
        <label className="label-container">
          <span className="input-label">
            {translate('total_cost', language)}
          </span>
          <input
            className="loan-calculator-result"
            name="totalCost"
            value={`${
              result.totalCost ? result.totalCost - loanAmount : 0
            } ${translate('currency_symbol', language)}`}
            disabled
          />
        </label>
      </div>
    </div>
  )
}

LoanCalculator.propTypes = {
  language: PropTypes.string
}

export default LoanCalculator
