export const languages = [
  { name: 'English', code: 'en' },
  { name: 'Finnish', code: 'fi' },
  { name: 'Swedish', code: 'sv' },
];

const translate = (key, lang) => {
  if (!translations[lang]) return `Could not find language ${lang}:${key}`;
  if (!translations[lang][key]) return `Translation key missing ${lang}:${key}`;
  return translations[lang][key];
};

const translations = {
  en: {
    loan_amount: 'Loan amount',
    loan_period: 'Loan period',
    interest_rate: 'Interest rate',
    amortization_cost: 'Amortization cost',
    admin_fee: 'Administration fee',
    total_cost: 'Total cost:',
    effective_interest_rate: 'effective interest rate',
    currency_symbol: '€',
    arrangement_fee: 'Arrangement fee'
  },
  sv: {
    loan_amount: 'Lånebelopp',
    loan_period: 'Låneperiod (mån)',
    interest_rate: 'Ränta (%)',
    amortization_cost: 'Amorteringskostnad:',
    admin_fee: 'Återkommande månadsavgifter',
    total_cost: 'Totalkostnad:',
    effective_interest_rate: 'Effektiv ränta:',
    currency_symbol: 'kr',
    arrangement_fee: 'Uppläggningsavgift'
  },
  fi: {
    loan_amount: 'Laina määrä',
    loan_period: 'Laina aika',
    interest_rate: 'Korko',
    amortization_cost: 'Kuukausimaksu',
    admin_fee: 'kuukausikustannus:',
    total_cost: 'Kokonaiskustannus:',
    effective_interest_rate: 'Sisäinen korko:',
    currency_symbol: '€',
    arrangement_fee: 'Nostoprovisio'
  },
};

export default translate;
