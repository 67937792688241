import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/main'
import HeaderMeta from '../components/HeaderMeta'
import ProgressiveBgImage from '../components/ProgressiveBgImage'
import LoanGrid from '../components/LoanGrid'
import LoanCalculator from '../components/LoanCalculator'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props

    const siteTitle = data.site.siteMetadata.title
    const lenders = data.lenders.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <HeaderMeta
          title="Räkna ut ränta med hjälp av vår låneräknare - Bluemonday.se"
          description=""
        />
        <ProgressiveBgImage
          hqImage="/images/office-desk.jpg"
          lqImage="/images/office-desk-lq.jpg"
        >
          <section className="section content-box transparent">
            <div className="hero-header-box">
              <h1>Räkna ränta och få överblick med en lånekalkylator</h1>
              <p>Gör upp en lånekalkyl smidigt på nätet</p>
            </div>
          </section>
          <section className="section content-box">
            <LoanCalculator />
          </section>
          <LoanGrid
            items={lenders.map(({ node }) => {
              return {
                title: node.frontmatter.title || node.fields.slug,
                url: node.fields.slug,
                image: node.frontmatter.logo,
                rating: node.frontmatter.rating,
                bullet_points: node.frontmatter.bullet_points,
                affiliate_link: node.frontmatter.affiliate_link,
                rating: node.frontmatter.rating,
                age_limit: node.frontmatter.age_limit,
                max_loan_amount: node.frontmatter.max_loan_amount,
                min_loan_amount: node.frontmatter.min_loan_amount,
                min_loan_period: node.frontmatter.min_loan_period,
                max_loan_period: node.frontmatter.max_loan_period,
                is_featured: node.frontmatter.is_featured,
                lender_type: node.frontmatter.lender_type
              }
            })}
          />
          <section className="section content-box">
            <h2>Varför en lånekalkyl?</h2>
            <p>
              Att låna pengar är något som många tycker känns som ett stort
              åtagande, och det kan vara svårt att förstå bankmännens språk.
              Lånebelopp, amorteringstid, ränta och inte minst din finansiella
              situation är alla delar som ska räknas in då ett lån beviljas, och
              det är viktigt att du själv är väl införstådd med låneprocessen,
              så att du går med på ett tryggt lån med bästa möjliga villkor.
              Något som kan hjälpa till på vägen är en lånekalkyl, där du enkelt
              kan räkna ut vad det kommer kosta just dig att ta ett visst lån.
              En bra lånekalkyl kommer att ge dig stenkoll på hela beloppet som
              skall betalas, inklusive exempelvis räntor och andra kostnader.
            </p>
            <h2> Hur fungerar en lånekalkyl?</h2>
            <p>
              Det finns många olika typer av lån, både snabba mindre lån och
              större lån man tar exempelvis vid bostadsköp som man betalar
              tillbaka över många år. Om du går i tankarna om att köpa en bostad
              kan vi rekommendera dig en bra kalkylator som hjälper dig att
              skapa en bolånekalkyl för att ha en bra översikt av räntekostnader
              och amorteringskrav. Oavsett typ av lån kan du använda dig av en
              lånekalkyl. Du skriver helt enkelt in vissa uppgifter och sedan så
              beräknas hur mycket lånet kommer kosta dig per månad. Det du
              behöver veta för att använda kalkylen är summan på lånet, under
              hur lång tid du ska betala tillbaka det, och vilken ränta du har.
              Resultatet visas i en tabell där du ser hur mycket du betalar per
              månad och vad den slutgiltiga amorteringskostnaden blir.
            </p>
            <h2>Vad betyder lånekalkyl? </h2>
            <p>
              Det många tycker är jobbigt med lån är just att få klarhet i vad
              det egentligen kommer att kosta. På pappret låter det så enkelt
              att låna pengar, men sedan tillkommer alltid kostnader som man
              inte alltid förstår. Innan du använder en automatisk kalkyl kan
              det därför vara bra att se till att du förstår alla delar i lånet.
              Det kanske mest krångliga är räntan, som alltså avgör hur mycket
              mer utöver det faktiska lånet du betalar. Du kan välja mellan
              rörlig eller fast ränta vilket naturligtvis påverkar hur mycket du
              kommer att betala. Det finns också olika typer av lån som kallas
              serielån och annuitetslån, vilket måste anges i kalkylberäkningen.
              Vid serielån så kan du välja att betala mer i början av lånet och
              mindre allteftersom, medan du vid annuitetslån betalar exakt samma
              summa lånet ut.
            </p>

            <h2>Hur använder man en Lånekalkyl? </h2>
            <p>
              En av de stora fördelarna med en lånekalkyl är att du snabbt och
              enkelt kan kolla vad dina nuvarande lån kostar dig, men även
              beräkna hypotetiska lån. Du kanske går i tankar om att köpa en ny
              bil och vill se exakt vilket lån du kommer att tjäna bäst på. Går
              du bara in och pratar med bankerna kommer naturligtvis varje lån
              låta som det bästa valet för dig, men kalkylen talar klarspråk.
              Samla på dig olika erbjudanden om lån och gå sedan hem och beräkna
              dem alla i kalkylen. På så sätt ser du snabbt vad du mest kommer
              att tjäna på, och du kan leka med siffrorna för att se vad som
              skulle vara ditt ideallån. Sedan kan du utgå från detta när du
              letar nya alternativ.
            </p>
          </section>
        </ProgressiveBgImage>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    lenders: allMarkdownRemark(
      filter: { frontmatter: { content_type: { eq: "lender" } } }
      sort: { fields: [frontmatter___rating], order: [DESC] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            featured_image
            logo
            content_type
            affiliate_link
            rating
            age_limit
            max_loan_amount
            min_loan_amount
            min_loan_period
            max_loan_period
            affiliate_link
            is_featured
            lender_type
          }
        }
      }
    }
  }
`
